import {
  ICredTrackingModel,
  IDashboardInitialAndReCredDataModel,
  IDashboardInitialAndReCredSearchAndFilterParams,
  IEmployeeModel,
  IEmployeeSearchAndFilterParams,
  IPrivilegingModel,
} from "@helpers/interfaces/employeeFiles";
import { ICheckboxSelectDataStructure } from "@helpers/uiInterfaces/common";

export function setDefaultEmployeeFilesFilterData(): IEmployeeSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    locations: [] as Array<ICheckboxSelectDataStructure>,
    department: [] as Array<ICheckboxSelectDataStructure>,
    employeeStatus: "All",
  };

  return data;
}

export function setDefaultEmployeeData(): IEmployeeModel {
  const data = {
    hrmrUserId: 0,
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    employeeType: "",
    gender: "0",
    tempColExist_UserId: "",
    email: "",
    isActive: false,
    dob: null,
    credentialsId: 0,
    credentialsName: "",
    departmentId: 0,
    department: "",
    mobileNo: "",
    empAppTypePropId: 0,
    terminationDate: null,
    dateOfHire: null,
    alternateEmail: "",
    supervisor: 0,
    supervisorName: "",
    billingSupervisor: 0,
    billingSupervisorName: "",
    isUserName: "Email",
    userName: "",
    password: "",
    enableWebPortal: false,
    employeeStatus: "",
    emergencyContact: "",
    emergencyContactName: "",
    profilePicture: "",
    profilePicturePath: "",
    isMalpractice: false,
    comment: "",
    reasonForTermination: "",
    vendorId: "",
    dateAddedToWatchdog: null,
    employmentStartDate: null,
    totalCount: 0,
    totalExpDocsCount: 0,
    locations: "",
    pronouns: "0",
    credContactName: "",
    credPrimaryEmail: "",
    credSecondaryEmail: "",
    credPhone: "",
    credPhoneExt: "",
    credFax: "",
    credAddress: "",
    credCityId: 0,
    credStateId: 0,
    credZip: "",
    file: null,
  };

  return data;
}

export function setDefaultDashboardInitialAndReCredFilterData(): IDashboardInitialAndReCredSearchAndFilterParams {
  const data = {
    search: "",
    employeeType: "1",
    locations: [] as Array<ICheckboxSelectDataStructure>,
    department: [] as Array<ICheckboxSelectDataStructure>,
    employeeStatus: "All",
  };

  return data;
}

export function setDefaultDashboardInitialAndReCredData(): IDashboardInitialAndReCredDataModel {
  const data = {
    hrmrUserId: 0,
    tempColExist_UserId: "",
    title: "",
    firstName: "",
    lastName: "",
    middleName: "",
    suffix: "",
    gender: "",
    email: "",
    isActive: false,
    dob: null,
    credentialsId: 0,
    credentialsName: "",
    departmentId: 0,
    department: "",
    mobileNo: "",
    empAppTypePropId: 0,
    terminationDate: null,
    dateOfHire: null,
    alternateEmail: "",
    supervisor: 0,
    supervisorName: "",
    billingSupervisor: 0,
    billingSupervisorName: "",
    isUserName: "",
    userName: "",
    enableWebPortal: false,
    employeeStatus: "",
    emergencyContact: "",
    emergencyContactName: "",
    profilePicture: "",
    profilePicturePath: "",
    isMalpractice: false,
    comment: "",
    reasonForTermination: "",
    vendorId: "",
    dateAddedToWatchdog: null,
    employmentStartDate: null,
    locations: "",
    employeeType: "",
    totalExpDocsCount: 0,
    totalCount: 0,
    isNPDBReport: false,
    statusOfApplication: "",
    hospitalName: "",
    notificationSchedule: "",
    createdBy: "",
    createdDate: null,
    updatedBy: "",
    updatedDate: null,
    userId: "",
    organizationId: "",
  };

  return data;
}

export function setDefaultCredTrackingData(): ICredTrackingModel {
  const data = {
    credentialingTrackingId: 0,
    hrmrUserId: 0,
    jobNo: null,
    associatedCredentialer: 0,
    requestCredType: 0,
    processingType: 0,
    credJobStatus: 0,
    workflowStartDate: null,
    workflowEndDate: null,
    beginningDate: null,
    endDate: null,
    insituitionRequesting: "",
    orgRequestOrgId: null,
    orgRequestUserId: null,
    comment: null,
    credTrackOrgIds: null,
    isHide: false,
    isDelete: false,
    associatedCredentialerName: null,
    organizationNames: null,
    totalCount: 0,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    userId: null,
    organizationId: null,
    assignedDataAnalysist: 0,
    assignedManager: 0,
    assignedManagerName: null,
    assignedDataAnalysistName: null,
    dateReportToClient: null,
    isCompleted: false,
    isEditEnalble: false,
  };

  return data;
}

export function setDefaultCredTrackingLogsData(): any {
  const data = {
    credTrackingLogId: 0,
    credentialingTrackingId: 0,
    hrmrUserId: 0,
    jobNo: "",
    associatedCredentialer: "",
    requestCredType: 0,
    processingType: 0,
    credJobStatus: 0,
    workflowStartDate: null,
    workflowEndDate: null,
    beginningDate: null,
    endDate: null,
    insituitionRequesting: 0,
    orgRequestOrgId: "",
    orgRequestUserId: "",
    comment: "",
    credTrackOrgIds: "",
    isHide: false,
    isDelete: false,
    organizationNames: "",
    associatedCredentialerName: "",
    totalCount: 0,
    createdBy: "",
    createdDate: null,
    updatedBy: "",
    updatedDate: null,
    userId: "",
    organizationId: "",
    dateReportToClient: null,
    isCompleted: false,
  };

  return data;
}

export function setDefaultPrivilegingData(): IPrivilegingModel {
  const data = {
    payerProviderId: 0,
    hrmrUserId: 0,
    credActivityId: 2,
    credActivity: "",
    credentialerUserId: "",
    payerId: 0,
    enrollmentStatus: "",
    enrollmentDate: null,
    enrollmentExpiryDate: null,
    userId: "",
    isDeleted: false,
    isHide: false,
    createdBy: "",
    createdDate: null,
    assignDate: null,
    requestingPrivilegingIds: "",
    privilegingCategoryIds: "",
    jobNo: null,
    credentialingTrackingId: 0,
    isPublicRecord: false,
  };

  return data;
}
