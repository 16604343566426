import { IUserRecordModel } from "@helpers/interfaces/authentication/Authentication";
import { IPracLocCoveringColleaguesModel, IPracticeLocationModel } from "@helpers/interfaces/credentialProfile/PracticeLocationInfoTab";

export function setDefaultPracticeLocationInfo(userRecord: IUserRecordModel): IPracticeLocationModel {

  const data = {
    practiceLocationId: 0,
    hrmrUserId: userRecord.hrmrUserId,
    isPrimary: false,
    practicingAddress: false,
    expectedStartDate: null,
    ifYesExpectedStartDate: null,
    physicianGroup: '',
    groupCorporateName: '',
    number: '',
    street: '',
    building: '',
    country: 0,
    state: 0,
    city: 0,
    zipCode: '',
    correspondenceHere: false,
    telephone: '',
    fax: '',
    officeEmail: '',
    individualTaxId: '',
    groupTaxId: '',
    organizationNPI: '',
    useIndividualTaxId: false,
    useGroupTaxId: false,
    typeofServiceProvided: '',
    doYouHaveCLIACertificate: false,
    doYouHaveCLIAWaiver: false,
    certificateNumber: '',
    certificateExpirationDate: null,
    locationId: 0,
    isFillinLocation: false,
    isOutreachLocation: false,
    estimatedWaitTimeId: 0,
    isElectronicPrescribing: true,
    isReferralRequired: false,
    specificPopulationTypes: '',
    isPersonTelehealthVisits: false,
    telehealthThirdPartyEntity: '',
    homeVisitStateIds: '',
    homeVisitCityIds: '',
    visitTypeIDs: '',
  };

  return data;
}

export function setDefaultCoveringColleagues(userRecord: IUserRecordModel): IPracLocCoveringColleaguesModel {

  const data = {
    coveringColleaguesId: 0,
    hrmrUserId: userRecord.hrmrUserId,
    lastName: '',
    firstName: '',
    mi: '',
    spaciltyCode: '',
    providerType: ''
  };

  return data;
}