import {
  IReqToCredModel,
  IReqToCredSearchAndFilterParams,
} from "@helpers/interfaces/req-to-credentialing";

export function setDefaultReqToCredData(): IReqToCredModel {
  const data = {
    applicantId: 0,
    requestedOrgId: null,
    networkOrganizationId: null,
    firstName: null,
    lastName: null,
    middleName: null,
    suffix: null,
    gender: null,
    email: null,
    dob: null,
    credentialsId: 0,
    credentialsName: null,
    anticipatedStartDate: null,
    departmentId: 0,
    requestType: 1,
    providerStatus: null,
    serviceAtSite: null,
    npiNumber: null,
    specialityDepartment: null,
    isSuppressInProviderDirectory: false,
    providerDirectory: 0,
    isActive: true,
    hRMRUserId: 0,
    isExisting: 0,
    isExistingLocation: false,
    serviceSiteName: null,
    serviceSiteAddress: null,
    serviceSiteCity: 0,
    serviceSiteState: 0,
    serviceSiteZip: null,
    credContactName: null,
    credPrimaryEmail: null,
    credSecondaryEmail: null,
    credPhone: null,
    credCellPhone: null,
    credFax: null,
    credAddress: null,
    credCityId: null,
    credStateId: null,
    credZip: null,
    credPhoneExt: null,
    currentStatus: 0,
    isRequestApprove: false,
    isHide: false,
    isDelete: false,
    isSync: false,
    createdBy: null,
    createdDate: null,
    updatedBy: null,
    updatedDate: null,
    affiliatedInstitutionIds: null,
    linkedOrgniazationIds: null,
    linkedDirectryIds: null,
    comments: "",
    associatedCredentialer: 0,
    assignedDataAnalysist: 0,
    assignedManager: 0,
    requestedBy: "",
    requestedDate: null,
  };

  return data;
}

export function setDefaultReqToCredPendingFilterData(): IReqToCredSearchAndFilterParams {
  const data = {
    search: "",
    appTab: "Pending",
    includeHidden: false,
  };

  return data;
}

export function setDefaultReqToCredCreatedFilterData(): IReqToCredSearchAndFilterParams {
  const data = {
    search: "",
    appTab: "Created",
    includeHidden: false,
  };

  return data;
}
