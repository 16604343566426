import genderPrefixData from "@constants/json-data/masters-data/gender-prefix-data";
import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import rushReqOptions from "@constants/json-data/masters-data/request-type-options";
import {
  ICredTrackingLogsModel,
  ICredTrackingModel,
  IEmpDetailedData,
  IEmpExclusionData,
  IEmpExclusionLogsData,
  IEmpPPShareDocData,
  IEmpShareDocReviewAndApprovalData,
  IEmpTrainingList,
  IEmployeeModel,
  IPrivilegingModel,
} from "@helpers/interfaces/employeeFiles";
import { IProviderDataModel } from "@helpers/interfaces/masters/Masters";
import { ICredJobStatus } from "@helpers/interfaces/trackingReport";
import { validateEmail } from "@utilities/form-validators";
import { removeSpacesAndDashes } from "@utilities/index";
import moment from "moment";

interface IEmployeeFormErrors {
  firstName: string;
  lastName: string;
  department: string;
  email: string;
  credentialsId: string;
  credPrimaryEmail: string;
  credSecondaryEmail: string;
}

export function validateEmployeeForm(values: IEmployeeModel) {
  const errors = {} as IEmployeeFormErrors;

  if (!values.firstName) {
    errors.firstName = "Required";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  }

  if (!values.departmentId) {
    errors.department = "Required";
  }

  if (!values.credentialsId) {
    errors.credentialsId = "Required";
  }

  if (values.email) {
    if (validateEmail(values.email) === true) {
      errors.email = "Invalid email";
    }
  } else {
    errors.email = "Required";
  }

  if (values.credPrimaryEmail) {
    if (validateEmail(values.credPrimaryEmail) === true) {
      errors.credPrimaryEmail = "Invalid email";
    }
  }

  if (values.credSecondaryEmail) {
    if (validateEmail(values.credSecondaryEmail) === true) {
      errors.credSecondaryEmail = "Invalid email";
    }
  }

  return errors;
}

export function createEmployeesMultipartFormData(employeeData: IEmployeeModel) {
  const employeeFormData = new FormData();
  const dateOfHire = employeeData.dateOfHire
    ? String(employeeData.dateOfHire)
    : "";
  const dob = employeeData.dob ? String(employeeData.dob) : "";
  const terminationDate = employeeData.terminationDate
    ? String(employeeData.terminationDate)
    : "";
  const datAddedToWatchdog = employeeData.dateAddedToWatchdog
    ? String(employeeData.dateAddedToWatchdog)
    : "";
  const employmentStartDate = employeeData.employmentStartDate
    ? String(employeeData.employmentStartDate)
    : "";

  employeeFormData.append("File", employeeData.file);
  employeeFormData.append("HRMRUserId", String(employeeData.hrmrUserId));
  employeeFormData.append("Title", employeeData.title || "");
  employeeFormData.append("FirstName", employeeData.firstName);
  employeeFormData.append("LastName", employeeData.lastName);
  employeeFormData.append("Email", employeeData.email);
  employeeFormData.append("isActive", String(employeeData.isActive));
  employeeFormData.append("DOB", dob);
  employeeFormData.append("CredentialsId", String(employeeData.credentialsId));
  employeeFormData.append(
    "CredentialsName",
    employeeData.credentialsName || ""
  );
  employeeFormData.append("DepartmentId", String(employeeData.departmentId));
  employeeFormData.append("MobileNo", employeeData.mobileNo || "");
  employeeFormData.append(
    "EmpAppTypePropId",
    String(employeeData.empAppTypePropId)
  );
  employeeFormData.append("TerminationDate", terminationDate);
  employeeFormData.append("DateOfHire", dateOfHire);
  employeeFormData.append("AlternateEmail", employeeData.alternateEmail || "");
  employeeFormData.append("Supervisor", String(employeeData.supervisor));
  employeeFormData.append(
    "BillingSupervisor",
    String(employeeData.billingSupervisor)
  );
  employeeFormData.append("EmployeeStatus", employeeData.employeeStatus || "");
  employeeFormData.append(
    "EmergencyContact",
    employeeData.emergencyContact || ""
  );
  employeeFormData.append(
    "EmergencyContactName",
    employeeData.emergencyContactName || ""
  );
  employeeFormData.append(
    "ReasonForTermination",
    employeeData.reasonForTermination || ""
  );
  employeeFormData.append("Locations", employeeData.locations);
  employeeFormData.append("Comment", employeeData.comment || "");
  employeeFormData.append("DateAddedToWatchdog", datAddedToWatchdog);
  employeeFormData.append("EmploymentStartDate", employmentStartDate);
  employeeFormData.append("Suffix", employeeData.suffix || "");
  employeeFormData.append("MiddleName", employeeData.middleName || "");
  employeeFormData.append("Gender", employeeData.gender);
  employeeFormData.append("EmployeeType", String(employeeData.employeeType));
  employeeFormData.append("ProfilePicture", employeeData.profilePicture || "");
  employeeFormData.append("Pronouns", employeeData.pronouns);

  employeeFormData.append(
    "ProfilePicturePath",
    employeeData.profilePicturePath || ""
  );
  employeeFormData.append("VendorId", employeeData.vendorId || "");
  employeeFormData.append(
    "CredContactName",
    employeeData.credContactName || ""
  );
  employeeFormData.append(
    "CredPrimaryEmail",
    employeeData.credPrimaryEmail || ""
  );
  employeeFormData.append(
    "CredSecondaryEmail",
    employeeData.credSecondaryEmail || ""
  );
  employeeFormData.append("CredPhone", employeeData.credPhone || "");
  employeeFormData.append("CredPhoneExt", employeeData.credPhoneExt);
  employeeFormData.append("CredFax", employeeData.credFax || "");
  employeeFormData.append("CredAddress", employeeData.credAddress || "");
  employeeFormData.append("CredCityId", String(employeeData.credCityId));
  employeeFormData.append("CredStateId", String(employeeData.credStateId));
  employeeFormData.append("CredZip", employeeData.credZip || "");
  return employeeFormData;
}

export function parseEmployeeExclusionList(
  employeesExclusionData: Array<IEmpExclusionData>
) {
  const _employeesExclusion = [];

  for (let employeeExclusion of employeesExclusionData) {
    const _employeeExclusion = {
      ...employeeExclusion,
      formattedUpdatedDate: employeeExclusion.updatedDate
        ? moment(employeeExclusion.updatedDate).format("MM/DD/YYYY h:mm:ss a")
        : "",
    };

    _employeesExclusion.push(_employeeExclusion);
  }

  return _employeesExclusion;
}

export function parseEmployeeExclusionLogsList(
  employeeExclusionLogsData: Array<IEmpExclusionLogsData>
) {
  const _employeesExclusionLogs = [];

  for (let employeeExclusionLog of employeeExclusionLogsData) {
    const _employeeExclusionLog = {
      ...employeeExclusionLog,
      formattedActivityDate: employeeExclusionLog.activityDate
        ? moment(employeeExclusionLog.activityDate).format(
            "MM/DD/YYYY h:mm:ss a"
          )
        : "",
    };

    _employeesExclusionLogs.push(_employeeExclusionLog);
  }

  return _employeesExclusionLogs;
}

export function parseEmployeeExclusionDetailedData(
  employeeExclusionDetailedData: Array<IEmpDetailedData>
) {
  const _employeesExclusionDetailedData = [];

  for (let employeeDetailedExclusionData of employeeExclusionDetailedData) {
    const _employeeDetailedExclusionData = {
      ...employeeDetailedExclusionData,
      formattedDOB: employeeDetailedExclusionData.dob
        ? moment(employeeDetailedExclusionData.dob).format(
            "MM/DD/YYYY h:mm:ss a"
          )
        : "",
      formattedexcldate: employeeDetailedExclusionData.excldate
        ? moment(employeeDetailedExclusionData.excldate).format(
            "MM/DD/YYYY h:mm:ss a"
          )
        : "",
      formattedreindate: employeeDetailedExclusionData.reindate
        ? moment(employeeDetailedExclusionData.reindate).format(
            "MM/DD/YYYY h:mm:ss a"
          )
        : "",
      formattedwaiverdate: employeeDetailedExclusionData.waiverdate
        ? moment(employeeDetailedExclusionData.waiverdate).format(
            "MM/DD/YYYY h:mm:ss a"
          )
        : "",
    };

    _employeesExclusionDetailedData.push(_employeeDetailedExclusionData);
  }

  return _employeesExclusionDetailedData;
}

const getStatus = (
  employeeReviewAndApprovalData: IEmpShareDocReviewAndApprovalData
) => {
  if (
    employeeReviewAndApprovalData.isApprove &&
    employeeReviewAndApprovalData.isReview
  ) {
    return "Reviewed and Approved";
  } else if (
    employeeReviewAndApprovalData.isReview &&
    !employeeReviewAndApprovalData.isApprove
  ) {
    return "Reviewed";
  } else if (
    employeeReviewAndApprovalData.isApprove &&
    !employeeReviewAndApprovalData.isReview
  ) {
    return "Approved";
  } else {
    return "None";
  }
};

const getDate = (
  employeeReviewAndApprovalData: IEmpShareDocReviewAndApprovalData
) => {
  if (
    employeeReviewAndApprovalData.isApproved &&
    employeeReviewAndApprovalData.isApprove
  ) {
    return employeeReviewAndApprovalData.isApproveDate
      ? moment(employeeReviewAndApprovalData.isApproveDate).format(
          "MM/DD/YYYY h:mm:ss a"
        )
      : "";
  } else {
    return employeeReviewAndApprovalData.uploadDate
      ? moment(employeeReviewAndApprovalData.uploadDate).format(
          "MM/DD/YYYY h:mm:ss a"
        )
      : "";
  }
};

export function parseEmployeeOnboardingReviewAndApprovalList(
  employeeReviewAndApprovalData: Array<IEmpShareDocReviewAndApprovalData>
) {
  const _employeesReviewAndApprovalData = [];

  for (let employeeDetailedExclusionData of employeeReviewAndApprovalData) {
    const _employeeReviewAndApprovalData = {
      ...employeeDetailedExclusionData,
      date: getDate(employeeDetailedExclusionData),
      status: getStatus(employeeDetailedExclusionData),
    };

    _employeesReviewAndApprovalData.push(_employeeReviewAndApprovalData);
  }

  return _employeesReviewAndApprovalData;
}

export const createPPSendForReviewPayload = (
  selectedRows: Array<IEmpPPShareDocData>,
  hrmrUserId: number
) => {
  const _selectedRows = [];
  for (let rowData of selectedRows) {
    const data = {
      ppRepositoryShareId: 0,
      documentName: rowData.documentName || "",
      documentPath: rowData.documentPath || "",
      ppRepositoryId: rowData.ppRepositoryId,
      isTinyMCE: rowData.isTinyMCE,
      uploadType: rowData.uploadType,
      description: rowData.description || "",
      userId: rowData.userId,
      hrmrUserId: hrmrUserId,
      isType: "Approve",
      ppType: "P",
      isReviewed: rowData.isReviewed,
      isApproved: rowData.isApproved,
      organizationId: rowData.organizationId,
      createdBy: rowData.createdBy || "",
      updatedBy: rowData.updatedBy || "",
    };
    _selectedRows.push(data);
  }
  return { pPShareDocs: _selectedRows };
};

export const parseEmployeeEmailedTemplateList = (
  emailedTemplates: Array<any>
) => {
  const _emailedTemplates = [];

  for (let emailedTemplate of emailedTemplates) {
    const _emailedTemplate = {
      ...emailedTemplate,
      sent_date: emailedTemplate.docSentDate
        ? moment(emailedTemplate.docSentDate).format("MM/DD/YYYY")
        : "",
      signed_date: emailedTemplate.docuSignDate
        ? moment(emailedTemplate.docuSignDate).format("MM/DD/YYYY")
        : "",
    };

    _emailedTemplates.push(_emailedTemplate);
  }

  return _emailedTemplates;
};

export function parseEmployeeTrainingList(
  employeeTrainingData: Array<IEmpTrainingList>,
  renderTrainingNameWithCME: (data: IEmpTrainingList) => void
) {
  const _employeesTrainingData = [];

  for (let empTrainingData of employeeTrainingData) {
    const _empTrainingData = {
      ...empTrainingData,
      renderTrainingName: renderTrainingNameWithCME(empTrainingData),
      assignDate: empTrainingData.assignedDate
        ? moment(empTrainingData.assignedDate).format("MM/DD/YYYY")
        : "--NA--",
      dateOfCompletion: empTrainingData.lastEdited
        ? moment(empTrainingData.lastEdited).format("MM/DD/YYYY")
        : "--NA--",
    };

    _employeesTrainingData.push(_empTrainingData);
  }

  _employeesTrainingData.sort((a, b) => {
    // Convert both names to lowercase to ensure case-insensitive sorting
    let nameA = a.trainingName.toLowerCase();
    let nameB = b.trainingName.toLowerCase();

    if (nameA < nameB) {
      return -1; // nameA should come before nameB in the sorted order
    }
    if (nameA > nameB) {
      return 1; // nameA should come after nameB in the sorted order
    }
    return 0; // names are equal
  });

  return _employeesTrainingData;
}

export const parseEmployeeTypeData = (
  employeesTypeData: Array<IProviderDataModel>
) => {
  const _employeesTypeData = [];

  for (let empTypesData of employeesTypeData) {
    const _empTypesData = {
      value: removeSpacesAndDashes(empTypesData.credentialType),
      label: empTypesData.credentialType,
    };

    _employeesTypeData.push(_empTypesData);
  }

  return _employeesTypeData;
};

export const renderPrefix = (prefix: string) => {
  return genderPrefixData.find((data) => data.value === prefix?.toString())
    ?.label;
};

export const parseCredTrackingData = (
  credTrackingDataList: Array<ICredTrackingModel>,
  jobStatus: Array<ICredJobStatus>
) => {
  const _credTrackingDataList = [];

  for (let credTrackingData of credTrackingDataList) {
    const _credTrackingData = {
      ...credTrackingData,
      credTypeValue: reqCredTypeOptions.find(
        (data) => data.value === credTrackingData.requestCredType
      )?.label,
      processingTypeValue: rushReqOptions.find(
        (data) => data.value === credTrackingData.processingType
      )?.label,
      credJobStatusValue: jobStatus.find(
        (data) => data.jobCredStatusId === credTrackingData.credJobStatus
      )?.jobCredStatus,
      workflowStartDate: credTrackingData.workflowStartDate
        ? moment(credTrackingData.workflowStartDate).format("MM/DD/YYYY")
        : "",
      workflowExpirationDate: credTrackingData.workflowEndDate
        ? moment(credTrackingData.workflowEndDate).format("MM/DD/YYYY")
        : "",
    };

    _credTrackingDataList.push(_credTrackingData);
  }

  return _credTrackingDataList;
};

export const parseCredTrackingLogsData = (
  credTrackingLogsDataList: Array<ICredTrackingLogsModel>,
  jobStatus: Array<ICredJobStatus>
) => {
  const _credTrackingLogsDataList = [];

  for (let credTrackingLogsData of credTrackingLogsDataList) {
    const _credTrackingLogsData = {
      ...credTrackingLogsData,
      credTypeValue: reqCredTypeOptions.find(
        (data) => data.value === credTrackingLogsData.requestCredType
      )?.label,
      processingTypeValue: rushReqOptions.find(
        (data) => data.value === credTrackingLogsData.processingType
      )?.label,
      credJobStatusValue: jobStatus.find(
        (data) => data.jobCredStatusId === credTrackingLogsData.credJobStatus
      )?.jobCredStatus,
      workflowStartDate: credTrackingLogsData.workflowStartDate
        ? moment(credTrackingLogsData.workflowStartDate).format("MM/DD/YYYY")
        : "",
      workflowExpirationDate: credTrackingLogsData.workflowEndDate
        ? moment(credTrackingLogsData.workflowEndDate).format("MM/DD/YYYY")
        : "",
    };

    _credTrackingLogsDataList.push(_credTrackingLogsData);
  }

  return _credTrackingLogsDataList;
};

interface ICredTrackingFormErrors {
  associatedCredentialer: string;
  requestCredType: string;
  processingType: string;
  credJobStatus: string;
  dateReportToClient?: string;
}

export const validateCredentialingTrackingForm = (
  values: ICredTrackingModel
) => {
  const errors = {} as ICredTrackingFormErrors;

  if (!values.associatedCredentialer) {
    errors.associatedCredentialer = "Required";
  }

  if (!values.requestCredType) {
    errors.requestCredType = "Required";
  }

  if (!values.processingType) {
    errors.processingType = "Required";
  }

  if (!values.credJobStatus) {
    errors.credJobStatus = "Required";
  }

  if (values.credJobStatus === 11 && !values.dateReportToClient) {
    errors.dateReportToClient = "Required";
  }

  return errors;
};
interface IPrivilegingFormErrors {
  payerId: string;
  enrollmentStatus: string;
  credentialerUserId: string;
}

export function validatePrvilegingForm(values: IPrivilegingModel) {
  const errors = {} as IPrivilegingFormErrors;

  if (!values.payerId) {
    errors.payerId = "Required";
  }

  if (!values.enrollmentStatus) {
    errors.enrollmentStatus = "Required";
  }

  if (!values.credentialerUserId) {
    errors.credentialerUserId = "Required";
  }

  return errors;
}
