import { ICredTrackingModel } from "@helpers/interfaces/employeeFiles";
import styles from "./Edit_CredentialingTracking.module.scss";
import { setDefaultCredTrackingData } from "@helpers/configMiddlewares/employeeFiles";
import {
  getCredTrackingData,
  upsertCredTracking,
} from "@services/employeeFiles/EditCredentialingTracking";
import { useAppSelector } from "@hooks/index";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import FormSelectInputControl from "@common/form-controls/FormSelectInputControl";
import MultiSelectInput from "@common/multi-select/MultiSelect";
import FormDateInputControl from "@common/form-controls/FormDateInputControl";
import FormTextareaInputControl from "@common/form-controls/FormTextareaInputControl";
import moment from "moment";
import {
  getAllNetworkByOrganizationId,
  getAllNetworkServiceSiteByOrganizationId,
} from "@services/master/master.services";
import {
  generateSelectedLanguageCodeJSON,
  getCodeIdsString,
  parseCredJobSelectControlsData,
  parseSelectContactsControlsData,
  parseSelectControlsData,
} from "@utilities/index";
import reqCredTypeOptions from "@constants/json-data/masters-data/reqCredTypeOptions";
import rushReqOptions from "@constants/json-data/masters-data/request-type-options";
import FormInputTextControl from "@common/form-controls/FormInputTextControl";
import { getAllJobCredStatus } from "@services/tracking-report";
import {
  parseSelectedClientsData,
} from "@pages/RequestCredentialing/utilities";
import { toastMessageActions } from "@features/ui/ToastMessage";
import messages from "@constants/messages";
import toastTypes from "@constants/toast-types";
import { validateCredentialingTrackingForm } from "../utilities";
import { getOrganizationContactsByType } from "@services/organizations/contact";
import { IEmployee_CredTrackingContentProps } from "@helpers/uiInterfaces/add-edit-employee";

const AddEditCredentialingTrackingEditor: React.FC<
  IEmployee_CredTrackingContentProps
> = (props) => {
  const {
    data,
    employeeData,
    jobId,
    setShowEditCredentialingTrackingModel,
    updateSelectedData,
  } = props;
  const dispatch = useDispatch<any>();
  const credTrackingState = useAppSelector(
    (state) => state.employeeFiles.editCredentialingTracking
  );
  const allNetworkServiceSiteByOrganizationId = useAppSelector(
    (state) => state.masters.allNetworkServiceSiteByOrganizationId
  );
  const clientsData = useAppSelector(
    (state) => state.masters.allNetworkByOrganizationId
  );
  const jobCredStatus = useAppSelector(
    (state) => state.trackingReport.jobCredStatus
  );
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );
  const [selectedOrganizations, setSelectedOrganizations] = useState<
    Array<any>
  >([]);
  const [credentialerOptions, setCredentialerOptions] = useState([]);
  const [analystOptions, setAnalystOptions] = useState([]);
  const [managerOptions, setManagerOptions] = useState([]);
  const [selectedCredJobStatus, setSelectedCredJobStatus] = useState(0);
  const [sendNotification, setSendNotification] = useState(false);
  const [selectedInstitutions, setSelectedInstitutions] = useState<Array<any>>(
    []
  );

  const formik = useFormik({
    initialValues:
      credTrackingState.credentialingTrackingData as ICredTrackingModel,
    validate: validateCredentialingTrackingForm,
    onSubmit: handleSaveControlClick,
  });
  const formikValues = formik.values;
  const formikErrors = formik.errors;

  useEffect(() => {
    loadCredentialingTrackingData();
  }, [data]);

  useEffect(() => {
    loadDependencies();
  }, []);

  async function loadDependencies() {
    await dispatch(getAllNetworkServiceSiteByOrganizationId());
    await dispatch(getAllJobCredStatus());
    await dispatch(getAllNetworkByOrganizationId(userRecord.organizationId));
    const credentialerRes = await dispatch(getOrganizationContactsByType(9));
    if (credentialerRes.status === 200)
      setCredentialerOptions(credentialerRes.data);

    const analystRes = await dispatch(getOrganizationContactsByType(10));
    if (analystRes.status === 200) setAnalystOptions(analystRes.data);

    const managerRes = await dispatch(getOrganizationContactsByType(11));
    if (managerRes.status === 200) setManagerOptions(managerRes.data);
  }

  async function loadCredentialingTrackingData() {
    if (data === null) {
      const _data: ICredTrackingModel = setDefaultCredTrackingData();
      const updatedData = { ..._data, jobNo: jobId };
      return formik.setValues(updatedData);
    }
    const response = await dispatch(
      getCredTrackingData(data.credentialingTrackingId)
    );
    if (response.status === 200 && clientsData.length > 0) {
      setSelectedCredJobStatus(response.data.credJobStatus);
      setSelectedOrganizations(
        parseSelectedClientsData(response.data.credTrackOrgIds, clientsData)
      );
      setSelectedInstitutions(
        generateSelectedLanguageCodeJSON(
          response.data.insituitionRequesting,
          allNetworkServiceSiteByOrganizationId
        )
      );
      formik.setValues(response.data);
    }
  }

  function handleSelectControlChange(name: string, value: string) {
    if (name === "credJobStatus") {
      if (+value !== 11) {
        formik.setFieldValue("dateReportToClient", null);
      }
      setSelectedCredJobStatus(+value);
      formik.setFieldValue(name, +value);
      return;
    }
    formik.setFieldValue(name, value);
  }

  async function handleSaveControlClick() {
    const values = {
      ...formikValues,
      hrmrUserId: employeeData?.hrmrUserId,
      sendNotification: sendNotification,
      credTrackOrgIds: getCodeIdsString(selectedOrganizations),
      insituitionRequesting: getCodeIdsString(selectedInstitutions),
    };

    delete values.isHide;
    delete values.isDelete;
    delete values.associatedCredentialerName;
    delete values.organizationNames;
    delete values.totalCount;
    delete values.userId;
    delete values.organizationId;
    delete values.assignedManagerName;
    delete values.assignedDataAnalysistName;
    delete values.isCompleted;
    delete values.isEditEnalble;

    const response = await dispatch(upsertCredTracking(values));
    if (response.status === 200) {
      setShowEditCredentialingTrackingModel(false);
      updateSelectedData({} as ICredTrackingModel);
      dispatch(
        toastMessageActions.showToast({
          message: messages.dataSuccessfullySaved,
          type: toastTypes.SUCCESS,
        })
      );
    }
  }

  const renderContent = () => {
    let associatedCredentialerError = "" as string | undefined;

    if (
      formikErrors.associatedCredentialer !== "" &&
      formik.touched.associatedCredentialer === true
    ) {
      associatedCredentialerError = formikErrors.associatedCredentialer;
    }

    let requestCredTypeError = "" as string | undefined;

    if (
      formikErrors.requestCredType !== "" &&
      formik.touched.requestCredType === true
    ) {
      requestCredTypeError = formikErrors.requestCredType;
    }

    let processingTypeError = "" as string | undefined;

    if (
      formikErrors.processingType !== "" &&
      formik.touched.processingType === true
    ) {
      processingTypeError = formikErrors.processingType;
    }

    let credJobStatusError = "" as string | undefined;

    if (
      formikErrors.credJobStatus !== "" &&
      formik.touched.credJobStatus === true
    ) {
      credJobStatusError = formikErrors.credJobStatus;
    }

    let dateReportedToClientError = "" as string | undefined;

    if (
      formikErrors.dateReportToClient !== "" &&
      formik.touched.dateReportToClient === true
    ) {
      dateReportedToClientError = formikErrors.dateReportToClient;
    }

    const jobNoControlAttributes = {
      label: "Job Id",
      type: "text",
      name: "jobNo",
      disabled: true,
      value: formikValues.jobNo,
      onChange: formik.handleChange,
    };

    const associatedCredentialerControlAttributes = {
      label: "Associated Credentialer",
      name: "associatedCredentialer",
      required: true,
      error: associatedCredentialerError,
      value: formikValues.associatedCredentialer,
      data: parseSelectContactsControlsData(credentialerOptions),
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const assignedDataAnalystControlAttributes = {
      label: "Assigned Data Analyst",
      name: "assignedDataAnalysist",
      value: formikValues.assignedDataAnalysist,
      data: parseSelectContactsControlsData(analystOptions),
      onChange: handleSelectControlChange,
    };

    const assignedManagerControlAttributes = {
      label: "Assigned Manager",
      name: "assignedManager",
      value: formikValues.assignedManager,
      data: parseSelectContactsControlsData(managerOptions),
      onChange: handleSelectControlChange,
    };

    const requestCredTypeControlAttributes = {
      label: "Request Cred Type",
      name: "requestCredType",
      required: true,
      error: requestCredTypeError,
      value: formikValues.requestCredType,
      data: reqCredTypeOptions,
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const processingTypeControlAttributes = {
      label: "Processing Type",
      name: "processingType",
      required: true,
      error: processingTypeError,
      value: formikValues.processingType,
      data: rushReqOptions,
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const credJobStatusControlAttributes = {
      label: "Cred/Job Status",
      name: "credJobStatus",
      required: true,
      error: credJobStatusError,
      value: formikValues.credJobStatus,
      data: parseCredJobSelectControlsData(jobCredStatus),
      onChange: handleSelectControlChange,
      onBlur: formik.handleBlur,
    };

    const dateReportedToClientControlAttributes = {
      label: "Date Reported To Client",
      name: "dateReportToClient",
      required: selectedCredJobStatus === 11,
      error: dateReportedToClientError,
      value: formikValues.dateReportToClient
        ? moment(formikValues.dateReportToClient).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("dateReportToClient", value);
      },
      onBlur: formik.handleBlur,
    };

    const organizationsMultiSelectControlAttributes = {
      data: parseSelectControlsData(clientsData),
      selectedValues: selectedOrganizations,
      onChange(data: any) {
        setSelectedOrganizations(data);
      },
    };

    const institutionRequestingThisControlAttributes = {
      data: parseSelectControlsData(allNetworkServiceSiteByOrganizationId),
      selectedValues: selectedInstitutions,
      onChange(data: any) {
        setSelectedInstitutions(data);
      },
    };

    const workflowStartDateControlAttributes = {
      label: "Credentialing From Date",
      name: "workflowStartDate",
      value: formikValues.workflowStartDate
        ? moment(formikValues.workflowStartDate).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("workflowStartDate", value);
      },
    };

    const workflowEndDateControlAttributes = {
      label: "Credentialing To Date",
      name: "workflowEndDate",
      value: formikValues.workflowEndDate
        ? moment(formikValues.workflowEndDate).format("YYYY-MM-DD")
        : "",
      onChange(value: string) {
        formik.setFieldValue("workflowEndDate", value);
      },
    };

    const commentControlAttributes = {
      label: "Comment",
      name: "comment",
      value: formikValues.comment,
      onChange: formik.handleChange,
    };

    // const requestedOrgNameControlAttributes = {
    //   label: "Requested Organization Name",
    //   type: "text",
    //   name: "orgRequestOrgId",
    //   value: formikValues.orgRequestOrgId,
    //   onChange: formik.handleChange,
    // };

    // const requestedUsernameControlAttributes = {
    //   label: "Requested Username",
    //   type: "text",
    //   name: "orgRequestOrgId",
    //   value: formikValues.orgRequestOrgId,
    //   onChange: formik.handleChange,
    // };

    const backButtonControlAttributes = {
      className: `btn btnorg w100`,
      onClick() {
        setShowEditCredentialingTrackingModel(false);
        updateSelectedData(null);
      },
    };

    const saveButtonControlAttributes = {
      className: `btn btnorg w100`,
      onClick() {
        setSendNotification(false);
        formik.handleSubmit();
      },
    };

    const sendNotificationControlAttributes = {
      className: `btn btnorg w150`,
      onClick() {
        setSendNotification(true);
        formik.handleSubmit();
      },
    };

    return (
      <div>
        <div className="row align-items-end mt-5">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormInputTextControl {...jobNoControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl
              {...associatedCredentialerControlAttributes}
            />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...assignedDataAnalystControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...assignedManagerControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...requestCredTypeControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...processingTypeControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormSelectInputControl {...credJobStatusControlAttributes} />
          </div>
          {selectedCredJobStatus === 11 && (
            <div className="col-md-3 col-sm-12 col-xs-12">
              <FormDateInputControl
                {...dateReportedToClientControlAttributes}
              />
            </div>
          )}
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-group">
              <div className="lbl">
                <label>Organization(s) - To be Priviledged</label>
              </div>
              <MultiSelectInput
                {...organizationsMultiSelectControlAttributes}
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="form-group">
              <div className="lbl">
                <label>Institutions Requesting This</label>
              </div>
              <MultiSelectInput
                {...institutionRequestingThisControlAttributes}
              />
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormDateInputControl {...workflowStartDateControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormDateInputControl {...workflowEndDateControlAttributes} />
          </div>
          {/* <div className="col-md-3 col-sm-12 col-xs-12">
            <FormInputTextControl {...requestedOrgNameControlAttributes} />
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormInputTextControl {...requestedUsernameControlAttributes} />
          </div> */}
          <div className="col-md-3 col-sm-12 col-xs-12">
            <FormTextareaInputControl {...commentControlAttributes} />
          </div>
        </div>
        <div className={styles.btnContainer}>
          <button {...saveButtonControlAttributes}>Save</button>
          <button {...sendNotificationControlAttributes}>
            Save & Send Notification
          </button>
          <button {...backButtonControlAttributes}>Back</button>
        </div>
      </div>
    );
  };

  return (
    <div id={styles.addEditCredentialingTrackingMain}>{renderContent()}</div>
  );
};

export default AddEditCredentialingTrackingEditor;
