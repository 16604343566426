import applicationTableColumnsTypes from "@constants/application-table-columns-types";

import { IApplicationTableColumn } from "@helpers/uiInterfaces/common";

const credentialingTrackingListColumns: Array<IApplicationTableColumn> = [
  {
    id: "jobNo",
    label: "Job",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "organizationNames",
    label: "Organization Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "credTypeValue",
    label: "Cred Type",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "processingTypeValue",
    label: "Processing Type",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "credJobStatusValue",
    label: "Job Status",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "associatedCredentialerName",
    label: "Credentialer Name",
    type: applicationTableColumnsTypes.STRING,
  },
  {
    id: "workflowStartDate",
    label: "Credentialing From Date",
    type: applicationTableColumnsTypes.CUSTOM,
  },
  {
    id: "workflowExpirationDate",
    label: "Credentialing To Date",
    type: applicationTableColumnsTypes.CUSTOM,
  },
];

export default credentialingTrackingListColumns;
